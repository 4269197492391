<template>
  <a-card class="mb10">
    <a-button size="small" @click="
          () => {
            $router.go(-1);
          }
        ">
      <LeftOutlined />返回
    </a-button>
    <span class="ml15 card_title">{{$route.query.company}}</span>
  </a-card>
  <a-card :bordered="false">
    <a-tabs>
      <a-tab-pane key="1" tab="二维码位数">
        <a-form :model="formState">
          <a-form-item label="大标位数">
            <template v-for="(v,index) in formState.big_code" :key="index">
              <a-input-number class="m-r-5" @change="(e)=>changeDige(e,index,'big_code')" :value="v" />
              <MinusCircleOutlined @click="delDige(index,'big_code')" class="m-r-5" />
            </template>
            <a-button @click="pushDigit('big_code')">添加位数</a-button>
          </a-form-item>
          <a-form-item label="中标位数">
            <template v-for="(v,index) in formState.middle_code" :key="index">
              <a-input-number class="m-r-5" @change="(e,target)=>changeDige(e,index,'middle_code',target)" :value="v" />
              <MinusCircleOutlined @click="delDige(index,'middle_code')" class="m-r-5" />
            </template>
            <a-button @click="pushDigit('middle_code')">添加位数</a-button>
          </a-form-item>
          <a-form-item label="小标位数">
            <template v-for="(v,index) in formState.small_code" :key="index">
              <a-input-number class="m-r-5"  @change="(e)=>changeDige(e,index,'small_code')" :value="v" />
              <MinusCircleOutlined @click="delDige(index,'small_code')" class="m-r-5" />
            </template>
            <a-button @click="pushDigit('small_code')">添加位数</a-button>
          </a-form-item>
          <a-form-item>
            <a-button type="primary" @click="saveDige">保存</a-button>
          </a-form-item>
        </a-form>
      </a-tab-pane>
      <a-tab-pane key="2" tab="二维码前缀">
        <div>
          <div>
            <div class="head">
              大标前缀 <a-button class="m-l-5" @click="pushPrefix('big_code')">添加前缀</a-button>
            </div>
            <div class="body">
              <div class="item m-b-15" v-for="(v,index) in prefix.big_code" :key="index" >
                <a-input type="text" style="width:200px" class="m-r-5" :value="v" @change="(e)=>changePrefix(e,index,'big_code')"  />
                <a-button @click="delPrefix(index,'big_code')">删除</a-button>
              </div>
            </div>
          </div>
          <div>
            <div class="head">
              中标前缀 <a-button  class="m-l-5" @click="pushPrefix('middle_code')">添加前缀</a-button>
            </div>
            <div class="body">
              <div class="item m-b-15" v-for="(v,index) in prefix.middle_code" :key="index" >
                <a-input type="text" style="width:200px" class="m-r-5"  :value="v" @change="(e)=>changePrefix(e,index,'middle_code')" />
                <a-button @click="delPrefix(index,'middle_code')">删除</a-button>
              </div>
            </div>
          </div>
          <div>
            <div class="head">
              小标前缀 <a-button  class="m-l-5" @click="pushPrefix('small_code')">添加前缀</a-button>
            </div>
            <div class="body">
              <div class="item m-b-15"  v-for="(v,index) in prefix.small_code" :key="index">
                <a-input type="text" style="width:200px" class="m-r-5" :value="v" @change="(e)=>changePrefix(e,index,'small_code')" />
                <a-button @click="delPrefix(index,'small_code')">删除</a-button>
              </div>
            </div>
          </div>
          <!-- <div>
            <div class="head">
              防伪码前缀 <a-button  class="m-l-5" @click="pushPrefix('security_code')">添加前缀</a-button>
            </div>
            <div class="body">
              <div class="item m-b-15" v-for="(v,index) in prefix.security_code" :key="index">
                <a-input type="text" style="width:200px" class="m-r-5"  :value="v" @change="(e)=>changePrefix(e,index,'security_code')"  />
                <a-button @click="delPrefix(index,'security_code')">删除</a-button>
              </div>
            </div>
          </div> -->
        </div>
            <a-button type="primary" @click="savePrefix">保存</a-button>
      </a-tab-pane>
       <a-tab-pane key="3" tab="基础配置">
         <baseSetting :brand_id="$route.query.brand_id" />
       </a-tab-pane>
    </a-tabs>
  </a-card>
</template>
<script>
import { LeftOutlined, MinusCircleOutlined } from "@ant-design/icons-vue";
import { reactive } from "@vue/reactivity";
import { code_rule_prefix, updateDigit, prefixDetail,updatePrefix } from "@/api/codeManage";
import { inject, onMounted } from "@vue/runtime-core";
import { $iscode } from "../../../utils/app";
import { message } from "ant-design-vue";
import baseSetting from './baseSetting.vue'
export default {
  components: { LeftOutlined, MinusCircleOutlined,baseSetting },
  setup() {
    var formState = reactive({
      big_code: [],
      middle_code: [],
      small_code: [],
    });
    var prefix = reactive({
      big_code: [],
      middle_code: [],
      small_code: [],
      security_code: [],
    });
    const route = inject("$route");
    const router = inject("$router");
    const initData = async () => {
      let result = await code_rule_prefix(route.query.brand_id)
        .then((res) => res.data)
        .catch((error) => error);
      for (const key in result.data) {
        formState[key] = result.data[key];
      }

      // 获取前缀

      let res = await prefixDetail(route.query.brand_id)
        .then((res) => res.data)
        .catch((error) => error);
        for (const key in res.data) {
        prefix[key] = res.data[key];
      }
    };
    // 添加位数
    const pushDigit = (type) => {
      if (!formState[type]) {
        formState[type] = [];
      }
      formState[type].push("");
    };
    // 删除位数
    const delDige = (index, type) => {
      formState[type].splice(index, 1);
    };
    // 更新数据
    const changeDige = (e, index, type, target) => {
      formState[type][index] = e;
    };
    // 保存位数
    const saveDige = async () => {
      // 校验
      // 大标位数
      if (!formState.big_code.length) {
        message.error("请设置大标位数");
        return;
      }
      if (
        formState.big_code.length &&
        formState.big_code.length != formState.big_code.filter((v) => v).length
      ) {
        message.error("请完善大标位数");
        return;
      }
      if (!formState.middle_code.length) {
        message.error("请设置中标位数");
        return;
      }
      if (
        formState.middle_code.length &&
        formState.middle_code.length !=
          formState.middle_code.filter((v) => v).length
      ) {
        message.error("请完善中标位数");
        return;
      }
      if (!formState.small_code.length) {
        message.error("请设置小标位数");
        return;
      }
      if (
        formState.small_code.length &&
        formState.small_code.length !=
          formState.small_code.filter((v) => v).length
      ) {
        message.error("请完善小标位数");
        return;
      }
      const hide = message.loading("保存中...", 0);
      let result = await updateDigit(route.query.brand_id, formState)
        .then((res) => res.data)
        .catch((error) => error);
      setTimeout(hide, 100);
      if ($iscode(result, true)) {
        router.go(-1);
      }
    };
    // 删除前缀
    const delPrefix=(index,type)=>{
         prefix[type].splice(index, 1);
    }
        // 更新数据
    const changePrefix = (e, index, type, target) => {
      prefix[type][index] = e.target.value;
    };
        // 添加前缀
    const pushPrefix=(type)=>{
       if (!prefix[type]) {
        prefix[type] = [];
      }
      prefix[type].push("");
    }
    // 保存前缀
    const savePrefix = async () => {
      // 校验
      const hide = message.loading("保存中...", 0);
      let result = await updatePrefix(route.query.brand_id, prefix)
        .then((res) => res.data)
        .catch((error) => error);
      setTimeout(hide, 100);
      if ($iscode(result, true)) {
        router.go(-1);
      }
    };
    onMounted(() => {
      initData();
    });
    return {
      formState,
      pushDigit,
      changeDige,
      delDige,
      delPrefix,
      saveDige,
      prefix,
      pushPrefix,
      changePrefix,
      savePrefix
    };
  },
};
</script>
<style lang="less" scoped>
.head {
  padding: 10px;
  background-color: #eee;
  margin-bottom: 30px;
}
</style>